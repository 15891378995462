import React from "react";
import FaqQuestion from "./faqquestion";
import * as s from "./faq.module.css";

const Faq = ({ items, title }) => {
  const defaultTitle = "Frequently Asked Questions";
  return (
    <section className={s.accordionBlock}>
      <div className="container">
        <div className="row ">
          <div className="col-12 d-flex justify-content-center align-items-center">
            <h2 className={`blog-title`}>
              {title !== undefined ? title : defaultTitle}
            </h2>
          </div>
        </div>
        <div className="row">
          <div className={`${s.accordion} col-12`}>
            {!!items &&
              items.map((q, i) => {
                return (
                  <FaqQuestion key={`faqquestion-${i}`} question={q.question}>
                    {q.answer}
                  </FaqQuestion>
                );
              })}
          </div>
        </div>
      </div>
    </section>
  );
};

export default Faq;

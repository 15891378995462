import { graphql } from "gatsby";
import React from "react";
import { StaticImage } from "gatsby-plugin-image";
import iconAdv1 from "../images/MenuManagement/icon-adv-1.png";
import iconAdv2 from "../images/MenuManagement/icon-adv-2.png";
import iconAdv3 from "../images/MenuManagement/icon-adv-3.png";
import Faq from "../components/faq/faq";
import Features from "../components/features/features";
import Footer from "../components/footer/footer";
import Header from "../components/header/header";
import ImageText from "../components/imageText/imageText";
import Integrations from "../components/integrations/integrations";
import News from "../components/news/news";
import Seo from "../components/seo";
import Testimonials from "../components/testimonials/testimonials";
import Title from "../components/title/title";
import Top from "../components/top/top";

import testimonialsState from "./../utils/testimonials";

// markup
const MenuManagementPage = ({ location, data }) => {
  const scrollToForm = "#";

  const excerpt = `Orders.co’s Master Menu Management system makes it easy to craft the
    perfect menu for all your ordering platforms in a fraction of the time.
    Time is money and we save you both.`;

  const topProps = {
    layout: 2,
    scrollToForm: scrollToForm,
    title: (
      <span style={{ fontSize: "39px" }}>
        Updating Online Menus Cause A Lot of Stress?
      </span>
    ),
    subtitle: (
      <>
        Craft the perfect menu for all your online
        <br className="d-none d-md-inline-block" /> ordering platforms in one
        click.
      </>
    ),
    image: (
      <StaticImage
        placeholder="none"
        loading="eager"
        alt="personalized ordering website"
        src="../images/MenuManagement/ordering-website.png"
        className="mw-100"
      />
    ),
  };

  const advs = [
    {
      bg: "#F1F7FF",
      icon: iconAdv1,
      title: (
        <span style={{ fontSize: "28.3px", lineHeight: "43px" }}>
          Design Beautiful Menus
        </span>
      ),
      description: (
        <p style={{ fontSize: "18.9px" }}>
          Create the perfect menu for all your online ordering platforms.
        </p>
      ),
    },
    {
      bg: "#DEFFEE",
      icon: iconAdv2,
      title: (
        <span style={{ fontSize: "28.3px", lineHeight: "43px" }}>
          Hassle-Free Menu Management
        </span>
      ),
      description: (
        <p style={{ fontSize: "18px" }}>
          Orders.co Menu Sync removes
          <br /> the hassle of managing
          <br /> multiple menus. One and done!
        </p>
      ),
    },
    {
      bg: "#FFF7E3",
      icon: iconAdv3,
      title: (
        <span style={{ fontSize: "28.3px", lineHeight: "43px" }}>
          No More Out-of-
          <br />
          Stock Orders
        </span>
      ),
      description: (
        <p style={{ fontSize: "18.9px" }}>
          Out of an item? Unpublish it from all your menus with just one click.
        </p>
      ),
    },
  ];

  const testimonialsProps = {
    layout: 2,
    items: [
      testimonialsState.slash_pizza_large,
    ],
    title: (
      <>
        Hear From
        <br /> Our Customers
      </>
    ),
    tag: "Testimonials",
  };

  const imageTextProps1 = {
    layout: "text-small",
    title: "Save Time With An Easy-to-Build Menu",
    titleStyle: "smaller",
    imagePosition: "left",
    description: (
      <p style={{ fontSize: "22.1px" }}>
        Orders.co’s Master Menu Management system makes it easy to craft the
        perfect menu for all your ordering platforms in a fraction of the time.
        Time is money and we save you both.
      </p>
    ),
    image: (
      <StaticImage
        placeholder="none"
        loading="eager"
        alt="menu management"
        src="../images/MenuManagement/menu-management.png"
      />
    ),
  };

  const imageTextProps2 = {
    layout: "text-small",
    title: "Update Online Menus From One Screen",
    titleStyle: "smaller",
    imagePosition: "right",
    description: (
      <p style={{ fontSize: "22.1px" }}>
        You no longer have to waste time signing into a handful of dashboards to
        manage your menus. Orders.co Menu Sync will link all your menus together
        for quick edits from one place!
      </p>
    ),
    image: (
      <StaticImage
        placeholder="none"
        loading="eager"
        alt="orders consolidation"
        src="../images/MenuManagement/orders-consolidation.png"
      />
    ),
  };
  const imageTextProps3 = {
    layout: "img-small",
    title: "Improve The Guest Experience With Engaging Menus",
    titleStyle: "smaller",
    imagePosition: "left",
    description: (
      <p style={{ fontSize: "22.1px" }}>
        Our Master Menu Management was built from the ground-up to be easy!
        Craft the perfect menu, alongside our experts, to improve your
        customer’s ordering experience and increase repeat business.
      </p>
    ),
    image: (
      <StaticImage
        placeholder="none"
        loading="eager"
        alt="ordering webist restaurant"
        src="../images/MenuManagement/restaurant-owner.png"
      />
    ),
  };

  const imageTextProps4 = {
    layout: "text-big",
    title:
      "No Online Sales Channel? Create A Commission-Free Ordering Website with Orders.co",
    imagePosition: "right",
    description: (
      <p style={{ fontSize: "19px" }}>
        Studies have shown that customers are more likely to order online with a
        restaurant-direct platform. Orders.co makes it easy to create a
        beautiful ordering website with nothing more than a menu! And the best
        part, it’s commission free!
      </p>
    ),
    image: (
      <StaticImage
        placeholder="none"
        loading="eager"
        alt="user-friendly dashboard"
        src="../images/MenuManagement/reporting.png"
      />
    ),
  };

  const questions = [
    {
      question: `How does Menu Sync Work?`,
      answer: `Menu Sync links your Master Menu to all connected providers so that all updates are
                uniformly pushed everywhere. Saving you time and energy.`,
    },
    {
      question: `What kind of Technical Support do orders.co offer?`,
      answer: `Orders.co has 24/7 technical and menu support teams to help our restaurant
                partners with anything they need.`,
    },
    {
      question: `How much does Orders.co cost?`,
      answer: `Orders.co has 3 affordable plans based on your unique needs. You never have to pay
                for unnecessary features. See the “Pricing” page for a detailed breakdown. `,
    },
    {
      question: `I charge different prices on each Delivery App. Does Orders.co support this?`,
      answer: `Yes! Orders.co’s Master Menu will allow you to set unique pricing on any given
                menu item for all providers.`,
    },
    {
      question: `How long does it take for menu updates to be pushed to my live menus?`,
      answer: `All menu updates are pushed to your live menus in 1 hour or less.
                For more urgent updates, you can contact an Orders.co representative.`,
    },
  ];

  return (
    <>
      <Seo
        title={"Menu Management"}
        description={excerpt}
        pageSeo={data.wpPage}
        location={location}
      />
      <Header scrollToForm={scrollToForm}></Header>
      <main>
        <Top {...topProps}></Top>
        <Features items={advs}></Features>
        <Testimonials {...testimonialsProps}></Testimonials>
        <Title underlined={true} layout="">
          Simple Menu Management
        </Title>
        <ImageText {...imageTextProps1}></ImageText>
        <ImageText {...imageTextProps2}></ImageText>
        <ImageText {...imageTextProps3}></ImageText>
        <Integrations scrollToForm={scrollToForm}></Integrations>
        <ImageText {...imageTextProps4}></ImageText>
        <News items={data.allWpPost} />
        <Faq items={questions} />
      </main>
      <Footer scrollToForm={scrollToForm}></Footer>
    </>
  );
};

export const query = graphql`
  query {
    wpPage(slug: { eq: "menu-management" }) {
      title
      id
      seo {
        metaKeywords
        metaDesc
        metaRobotsNofollow
        metaRobotsNoindex
        opengraphAuthor
        opengraphDescription
        title
        twitterDescription
        twitterTitle
        opengraphSiteName
        opengraphTitle
        opengraphUrl
        focuskw
        opengraphImage {
          localFile {
            url
            childImageSharp {
              fixed {
                src
                width
                height
              }
            }
            extension
          }
        }
        twitterImage {
          localFile {
            url
            childImageSharp {
              fixed {
                src
                width
                height
              }
            }
            extension
          }
        }
        opengraphType
      }
    }
    allWpPost(
      sort: {fields: [date], order: DESC}
      limit: 3
      skip: 0
      filter: {categories: {nodes: {elemMatch: {slug: {eq: "menu-management"}}}}}
    ) {
      nodes {
        excerpt
        uri
        date(formatString: "MMMM DD, YYYY")
        title
        excerpt
        featuredImage {
          node {
            gatsbyImage(width: 600,quality: 80)
            altText
            localFile {
              childImageSharp {
                gatsbyImageData(quality: 90, placeholder: TRACED_SVG, layout: FULL_WIDTH)
              }
            }
          }
        }
      }
    }
  }
`;

export default MenuManagementPage;

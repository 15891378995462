import React from "react";
import * as s from "./image-text.module.css";

const ImageText = ({
  title,
  titleStyle,
  description,
  imagePosition,
  layout,
  image,
}) => {
  let imageClasses = [
    "col-12",
    "d-flex",
    "justify-content-center",
    "align-items-center",
    "col-lg-6",
    "order-2",
  ];
  let textClasses = ["col-12", "col-lg-6", "order-1"];
  switch (layout) {
    case "equal-small":
      imageClasses.push("col-xl-5");
      textClasses.push("col-xl-5");
      break;
    case "text-small":
      imageClasses.push("col-xl-6");
      textClasses.push("col-xl-5");
      break;
    case "img-small":
      imageClasses.push("col-xl-5");
      textClasses.push("col-xl-6");
      break;
    case "text-big":
      imageClasses.push("col-xl-5");
      textClasses.push("col-xl-7");
      textClasses.push(s.v2);
      break;
    case "equal-big":
    default:
      imageClasses.push("col-xl-6");
      textClasses.push("col-xl-6");
      break;
  }
  if (imagePosition === "left") {
    imageClasses.push("order-lg-1");
    imageClasses.push("justify-content-xl-start");
    textClasses.push("order-lg-2");
  }
  if (imagePosition === "right") {
    imageClasses.push("order-lg-2");
    imageClasses.push("justify-content-xl-end");
    textClasses.push("order-lg-1");
  }

  let titleClasses = [s.imageTextTitle];
  switch (titleStyle) {
    case "small":
      titleClasses.push(s.small);
      break;
    case "smaller":
      titleClasses.push(s.smaller);
      break;
    default:
      break;
  }
  return (
    <section className={`${s.imageTextBlock} image-text-block`}>
      <div className="container">
        <div className="row align-items-center justify-content-center">
          <div className={imageClasses.join(" ")}>{image}</div>
          <div className={textClasses.join(" ")}>
            <h2 className={titleClasses.join(" ")}>{title}</h2>
            <div className={s.imageTextDescription}>{description}</div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ImageText;

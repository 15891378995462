import React from "react";
import * as s from "./top.module.css";
import TrialButton from "./../trialButton/trialButton";
import { Link } from "gatsby";

const Top = ({
  scrollToForm,
  topImage,
  layout,
  title,
  subtitle,
  content,
  image,
  imageSize,
  rightText,
  links,
  hideLine,
  btnClasses,
  hideButton,
  customButton,
  notUseH1,
  styles,
}) => {
  if (hideLine === undefined) {
    hideLine = false;
  }

  if (btnClasses === undefined) {
    btnClasses = [];
  }

  let leftClasses = [];
  let rightClasses = [];
  switch (imageSize) {
    case "big":
      leftClasses.push("col-xl-5");
      rightClasses.push("col-xl-7");
      break;

    default:
      leftClasses.push("col-xl-6");
      rightClasses.push("col-xl-6");
      break;
  }

  return (
    <>
      {layout === 1 && (
        <>
          <section
            className={`${s.topBlock} ${hideLine === true ? s.hideLine : ""} ${s.v1
              }`}
          >
            <div className="container">
              <div className="row ">
                <div className="col-12">
                  {!!title && (
                    <h1 className={`${s.title} title text-center`}>{title}</h1>
                  )}
                </div>
              </div>
              <div className="row align-items-end">
                <div className="col-12 col-xl-5 px-xl-0 order-2 order-lg-1">
                  <div className={s.subtitle}>{subtitle}</div>
                  <TrialButton
                    scrollToForm={scrollToForm}
                    classes={[
                      s.trialButton,
                      ...btnClasses,
                      "trial-button bg-orange cursor-pointer d-block mx-auto ml-xl-0",
                    ]}
                  >
                    SIGN UP FOR A FREE TRIAL
                  </TrialButton>
                </div>
                <div
                  className={`col-12 col-xl-7 ${s.topBlockRight} mt-5 mt-xl-0 order-1 order-lg-2`}
                >
                  {image}
                </div>
              </div>
            </div>
          </section>
        </>
      )}
      {layout === 2 && (
        <>
          <section
            className={`${s.topBlock} ${hideLine === true ? s.hideLine : ""} ${s.v2
              }`}
          >
            <div className="container">
              <div className="row justify-content-between">
                <div className={`col-12 ${leftClasses.join(" ")} px-xl-0`}>
                  {!!topImage && (
                    <div className="d-none d-xl-flex justify-content-end">
                      {topImage}
                    </div>
                  )}
                  {!!title && (
                    <>
                      {!notUseH1 && <h1 className={`title text-center text-xl-left ${s.title}`}>
                        {title}
                      </h1>}
                      {!!notUseH1 && <div className={`title text-center text-xl-left ${s.title}`}>
                        {title}
                      </div>}
                    </>
                  )}
                  {!!subtitle && (
                    <h3 className={`${s.subtitle}`}>
                      {subtitle}
                    </h3>
                  )}
                  {!!customButton && <div className="d-none d-xl-inline-block">{customButton}</div>}
                  {(!hideButton && !customButton) &&
                    <TrialButton
                      scrollToForm={scrollToForm}
                      classes={[
                        s.trialButton,
                        ...btnClasses,
                        "trial-button bg-orange cursor-pointer d-none d-xl-inline-block",
                      ]}
                    >
                      Sign up for a free trial
                    </TrialButton>
                  }
                </div>
                <div
                  className={`col-12 ${rightClasses.join(" ")} text-center ${s.topBlockRight
                    }`}
                >
                  {image}
                </div>
                <div className="d-xl-none col-12 text-center">
                  {!!customButton && customButton}
                  {(!hideButton && !customButton) &&
                    <TrialButton
                      scrollToForm={scrollToForm}
                      classes={[
                        s.trialButton,
                        "trial-button bg-orange cursor-pointer d-inline-block mt-md-4",
                      ]}
                    >
                      Sign up for a free trial
                    </TrialButton>
                  }
                </div>
              </div>
            </div>
          </section>
        </>
      )}
      {layout === 3 && (
        <>
          <section
            className={`${s.topBlock} ${hideLine === true ? s.hideLine : ""} ${s.v3}`}
            style={styles}
          >
            <div className="container">
              <div className="row justify-content-between">
                <div className="col-12 col-xl-6 col-md-6 d-flex flex-column justify-content-center">
                  {!!title && <h1 className={`title ${s.title}`}>{title}</h1>}
                  <div className="mt-4 mb-5 d-block d-md-none">
                    {image}
                    {!!rightText && <div className={s.topText}>{rightText}</div>}
                  </div>
                  {!!content && (
                    <div className={s.topBlockContent}>{content}</div>
                  )}
                  {!hideButton && <TrialButton
                    scrollToForm={scrollToForm}
                    classes={[
                      s.trialButton,
                      ...btnClasses,
                      "trial-button bg-orange cursor-pointer",
                    ]}
                  >
                    Sign up for a free trial
                  </TrialButton>
                  }
                </div>
                <div className="col-12 col-xl-5 col-md-6 d-none d-md-block">
                  {image}
                  {!!rightText && <div className={s.topText}>{rightText}</div>}
                </div>
              </div>
            </div>
          </section>
        </>
      )}
      {layout === 4 && (
        <>
          <section
            className={`${s.topBlock} ${hideLine === true ? s.hideLine : ""} ${s.v4
              }`}
          >
            <div className="container">
              <div className="row justify-content-between mt-5">
                <div className="col-12">
                  {!!title && <h1 className={`title ${s.title}`}>{title}</h1>}
                </div>
                <div className="col-12">
                  {!!content && (
                    <div className={s.topBlockContent}>{content}</div>
                  )}
                </div>
                <div className="col-12">
                  {/* <div className={s.linkButtonsOuter}>
                                        <div className={s.linkButtonsInner}>
                                            <div className={s.linkButtons}>
                                                {
                                                    links.length > 0 &&
                                                    links.map((l, i) => {
                                                        return (
                                                            <Link key={`top-link-${i}`} to={l.to} className={`${s.linkButton}`}>
                                                                <span>{l.title}</span>
                                                            </Link>
                                                        )
                                                    })
                                                }
                                            </div>
                                        </div>
                                    </div> */}
                  <div className={s.linkButtonsOuter}>
                    <div className={s.linkButtonsInner}>
                      <div className={`${s.linkButtons}`}>
                        {links.length > 0 &&
                          links.map((l, i) => {
                            return (
                              <Link
                                key={`top-link-${i}`}
                                to={l.to}
                                className={`${s.linkButton}`}
                              >
                                <span>{l.title}</span>
                              </Link>
                            );
                          })}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </>
      )}
    </>
  );
};

export default Top;

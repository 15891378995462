import React from "react";
import Slider from "react-slick";
import SlickArrowLeft from "./../SlickArrowLeft";
import SlickArrowRight from "./../slickArrowRight";
import * as s from "./features.module.css";

const Features = ({ items, title, smallTitle, classes, showNumbers }) => {
  if (!classes) {
    classes = ["align-items-start"];
  }

  if (!smallTitle) {
    smallTitle = false;
  }

  if (!showNumbers) {
    showNumbers = false;
  }

  const featuresSettingsV1 = {
    dots: true,
    arrows: false,
    slidesToShow: 3,
    prevArrow: <SlickArrowLeft />,
    nextArrow: <SlickArrowRight />,
    responsive: [
      {
        breakpoint: 991.9,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 575.9,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
    <>
      <section className={`${s.featuresBlock} features-block`}>
        <div className="container-md">
          {!!title && (
            <div className="row">
              <div className="col-12">
                <h2
                  className={`${s.title} ${smallTitle ? s.small : ""
                    } title text-left mt-md-0`}
                >
                  {title}
                </h2>
              </div>
            </div>
          )}
          {/* <div className={`row justify-content-around ${classes.join(' ')}`}> */}
          <Slider {...featuresSettingsV1} className={`${classes.join(" ")}`}>
            {items.map((p, i) => {
              return (
                <div className="px-2 py-4 h-100" key={`feature-item-${i}`}>
                  <div
                    className={`${s.featureItem} h-100 d-flex flex-column align-items-center`}
                  >
                    {!!showNumbers && <div className={s.number}>
                      {i < 10 ? '0' + (i+1) : (i+1)}
                    </div>}
                    {!!p.icon && (
                      <div
                        className={`${s.iconContainer} d-flex align-items-center justify-content-center`}
                        style={{ backgroundColor: p.bg }}
                      >
                        <img
                          alt="icon-adv-1"
                          src={p.icon}
                          width="74"
                          height="74"
                        />
                      </div>
                    )}
                    {!!p.number && (
                      <div
                        className={`${s.numberContainer} d-flex align-items-center justify-content-center`}
                      >
                        <span>{p.number}</span>
                      </div>
                    )}
                    {!!p.title && (
                      <div className={s.featureTitle}>{p.title}</div>
                    )}
                    {!!p.description && (
                      <div className={s.featureDescription}>
                        {p.description}
                      </div>
                    )}
                  </div>
                </div>
              );
            })}
          </Slider>
          {/* </div> */}
        </div>
      </section>
    </>
  );
};

export default Features;

// extracted by mini-css-extract-plugin
export var moreLink = "testimonials-module--more-link--d4993";
export var ourCustomers = "testimonials-module--our-customers--f30db";
export var ourCustomers1Bg = "testimonials-module--our-customers1-bg--682af";
export var ourCustomers1__bg = "testimonials-module--our-customers1__bg--0221b";
export var ourCustomers2Bg = "testimonials-module--our-customers2-bg--d41d6";
export var ourCustomersBlock = "testimonials-module--our-customers-block--5288b";
export var ourCustomersDescription = "testimonials-module--our-customers-description--c5080";
export var ourCustomersP = "testimonials-module--our-customers-p--e04e1";
export var ourCustomersTitle = "testimonials-module--our-customers-title--c65a0";
export var ourCustomers__block = "testimonials-module--our-customers__block--9e2bb";
export var row = "testimonials-module--row--f9812";
export var star = "testimonials-module--star--4d57d";
export var stars = "testimonials-module--stars--ad67d";
export var testBackImg = "testimonials-module--test-back-img--314b0";
export var testContent = "testimonials-module--test-content--d5d79";
export var testimonialAuthor = "testimonials-module--testimonial-author--e5ec6";
export var testimonialBadge = "testimonials-module--testimonial-badge--bb378";
export var testimonialBadgeContent = "testimonials-module--testimonial-badge-content--8956c";
export var testimonialMedia = "testimonials-module--testimonial-media--d13dc";
export var testimonialPosition = "testimonials-module--testimonial-position--88f8a";
export var testimonials = "testimonials-module--testimonials--28af8";
export var testimonialsTag = "testimonials-module--testimonials-tag--a0d9b";
export var testimonialsText = "testimonials-module--testimonials-text--634bd";
export var testimonialsTitle = "testimonials-module--testimonials-title--7a1ad";
export var title = "testimonials-module--title--87bc4";
export var titleImg = "testimonials-module--title-img--251eb";
export var v1 = "testimonials-module--v1--cca6d";
export var v2 = "testimonials-module--v2--28b8e";
export var v4 = "testimonials-module--v4--14bfa";
export var v5 = "testimonials-module--v5--48eb7";
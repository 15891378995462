import React from "react";
import { useState } from "react";
import * as s from "./faqquestion.module.css";

const FaqQuestion = ({ question, children }) => {
  const [opened, setOpened] = useState(false);

  const toggleOpened = e => {
    e.preventDefault();
    setOpened(!opened);
  };

  return (
    <>
      <div
        className={
          (opened ? s.collapsibleOpen : "") + ` ${s.accordionCollapsible}`
        }
      >
        {/* <div className={s.collapsibleHeader} onClick={toggleOpened} role='button'>
                    <p>{question}</p>
                </div> */}
        <button className={s.collapsibleHeader} onClick={toggleOpened}>
          <span>{question}</span>
        </button>
        <div className={s.collapsibleContent}>
          <p>{children}</p>
        </div>
      </div>
    </>
  );
};

export default FaqQuestion;

import React from "react";
import { StaticImage } from "gatsby-plugin-image";
import Slider from "react-slick";
import SlickArrowLeft from "./../SlickArrowLeft";
import SlickArrowRight from "./../slickArrowRight";
import * as s from "./testimonials.module.css";
import { Link } from 'gatsby';

const Testimonials = ({ items, title, titleImg, tag, layout }) => {
  const testimonialsSettingsV1 = {
    dots: false,
    arrows: true,
    slidesToShow: 3,
    prevArrow: <SlickArrowLeft />,
    nextArrow: <SlickArrowRight />,
    responsive: [
      {
        breakpoint: 991.9,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 767.9,
        settings: {
          slidesToShow: 1,
          // arrows: false,
        },
      },
    ],
  };

  const testimonialsSettingsV2 = {
    dots: false,
    arrows: true,
    slidesToShow: 1,
    infinite: true,
    prevArrow: <SlickArrowLeft />,
    nextArrow: <SlickArrowRight />,
    responsive: [],
  };

  const testimonialsSettingsV3 = {
    dots: true,
    arrows: false,
    slidesToShow: 1,
    infinite: true,
  };

  return (
    <>
      {layout === 1 && (
        <section className={`${s.testimonials} ${s.v1} testimonials v1`}>
          <div className="container">
            <div className="row">
              <div className="col-12">
                {!!tag && <div className={`${s.testimonialsTag}`}>{tag}</div>}
                <h2 className={`${s.testimonialsTitle}`}>{title}</h2>
              </div>
            </div>
            <Slider
              {...testimonialsSettingsV1}
              className={`testimonials-slider arrows-top`}
            >
              {items.map((t, i) => {
                return (
                  <div className="row mx-0 h-100" key={`testimonial-${i}`}>
                    {!!t.badge &&
                      <div className="col-12 mb-3">
                        <div className={`${s.testimonialBadge}`}>
                          <div className={`${s.testimonialBadgeContent}`}>{t.badge}</div>
                        </div>
                      </div>
                    }
                    <div className="col-12 mb-3">
                      <div className={`${s.testimonialMedia} text-center`}>
                        {t.image}
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="d-flex flex-column align-items-md-center h-100">
                        <div className={`${s.testimonialsText}`}>{t.text}</div>
                        {!!t.to &&
                          <Link to={t.to} className="text-decoration-none">
                            <h4 className={`${s.testimonialAuthor}`}>{t.author}</h4>
                          </Link>
                        }
                        {!t.to &&
                          <h4 className={`${s.testimonialAuthor}`}>{t.author}</h4>
                        }
                        <span className={`${s.testimonialPosition}`}>
                          {t.position}
                        </span>
                        {/* <a href={`#${t.author}`} className={`${s.moreLink}`}>
                          <span className="mr-2">Learn More</span>
                          <svg
                            width="17"
                            height="19"
                            viewBox="0 0 17 19"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M2 8.25684C1.30964 8.25684 0.75 8.81648 0.75 9.50684C0.75 10.1972 1.30964 10.7568 2 10.7568L2 8.25684ZM15.8839 10.3907C16.372 9.90256 16.372 9.11111 15.8839 8.62295L7.92893 0.668001C7.44078 0.179845 6.64932 0.179845 6.16116 0.668001C5.67301 1.15616 5.67301 1.94761 6.16116 2.43577L13.2322 9.50683L6.16117 16.5779C5.67301 17.0661 5.67301 17.8575 6.16117 18.3457C6.64932 18.8338 7.44078 18.8338 7.92893 18.3457L15.8839 10.3907ZM2 10.7568L15 10.7568L15 8.25683L2 8.25684L2 10.7568Z"
                              fill="#FF6B00"
                            />
                          </svg>
                        </a> */}
                      </div>
                    </div>
                  </div>
                );
              })}
            </Slider>
          </div>
        </section>
      )}
      {layout === 2 && (
        <section className={`${s.testimonials} ${s.v2} testimonials v2`}>
          <div className="container">
            <div className="row">
              <div className="col-12">
                {!!tag && <div className={`${s.testimonialsTag}`}>{tag}</div>}
                <h2 className={`${s.testimonialsTitle}`}>{title}</h2>
              </div>
            </div>
            <Slider
              {...testimonialsSettingsV2}
              className={`testimonials-slider`}
            >
              {items.map((t, i) => {
                return (
                  <div
                    className="row d-flex align-items-center"
                    key={`testimonial-${i}`}
                  >
                    <div className="col-lg-7 col-xl-7 mb-3 mb-lg-0">
                      <div className={`${s.testimonialMedia}`}>{t.image}</div>
                    </div>
                    <div className="col-lg-5 offset-xl-1 col-xl-4">
                      <div className="pl-4 pr-1 px-md-0">
                        <StaticImage
                          placeholder="none"
                          loading="eager"
                          src="../../images/testimonials/quotes-v2.png"
                          alt="quotes"
                        />
                        <div className={`${s.testimonialsText}`}>{t.text}</div>
                        <div className={`${s.stars} d-flex`}>
                          <div className={s.star}></div>
                          <div className={s.star}></div>
                          <div className={s.star}></div>
                          <div className={s.star}></div>
                          <div className={s.star}></div>
                        </div>
                        {!!t.to &&
                          <Link to={t.to} className="text-decoration-none">
                            <h4 className={`${s.testimonialAuthor}`}>{t.author}</h4>
                          </Link>
                        }
                        {!t.to &&
                          <h4 className={`${s.testimonialAuthor}`}>{t.author}</h4>
                        }
                        <span className={`${s.testimonialPosition}`}>
                          {t.position}
                        </span>
                      </div>
                    </div>
                  </div>
                );
              })}
            </Slider>
          </div>
        </section>
      )}
      {layout === 3 && (
        <>
          <section className={`title-block ${s.ourCustomers}`}>
            <div className="container">
              <h2 className={s.title}>{title}</h2>
            </div>
            {!!titleImg && (
              <div className={`${s.titleImg} d-none d-xl-block`}>
                {titleImg}
              </div>
            )}
          </section>
          <section className={`${s.ourCustomersBlock}`}>
            <Slider {...testimonialsSettingsV3} className={`container`}>
              {items.map((t, i) => {
                return (
                  <div
                    className="row d-flex align-items-center justify-content-center"
                    key={`testimonial-${i}`}
                  >
                    <div
                      className={`${s.ourCustomers1Bg} col-12 col-xl-5 col-md-5 d-flex justify-content-center justify-content-xl-start align-items-center order-1`}
                    >
                      {t.image}
                    </div>
                    <div className="col-12 col-xl-5 col-md-5 order-2">
                      <div className={s.ourCustomersDescription}>{t.text}</div>
                      {!!t.to &&
                        <Link to={t.to} className="text-decoration-none">
                          <h2 className={s.ourCustomersTitle}>{t.author}</h2>
                        </Link>
                      }
                      {!t.to &&
                        <h2 className={s.ourCustomersTitle}>{t.author}</h2>
                      }
                      <p className={s.ourCustomersP}>{t.position}</p>
                    </div>
                  </div>
                );
              })}
            </Slider>
          </section>
        </>
      )}

      {layout === 4 && (
        <>
          <section className={`${s.testimonials} ${s.v4}`}>
            <div className="container">
              <div className="row">
                <div className="col-12">
                  <h2 className={s.testimonialsTitle}>{title}</h2>
                </div>
              </div>

              <Slider {...testimonialsSettingsV3}>
                {items.map((t, i) => {
                  return (
                    <div key={`testimonial-${i}`}>
                      <div
                        className={`row align-items-start ${s.testBackImg}`}
                        style={{ backgroundImage: `url(${t.bgImage})` }}
                      >
                        <div className="col-lg-6 col-xl-6 mb-3 mb-lg-0 order-1 order-lg-2 d-xl-none">
                          <div className={s.testimonialMedia}>{t.image}</div>
                        </div>
                        <div
                          className={`col-lg-6 col-xl-6 order-2 order-lg-1 ${s.testContent}`}
                        >
                          <div className="mb-2 pb-1">
                            <StaticImage
                              placeholder="none"
                              loading="eager"
                              src="../../images/testimonials/quotes-v2.png"
                              alt="quotes"
                            />
                          </div>
                          <div className={s.testimonialsText}>{t.text}</div>
                          <div className={`${s.stars} d-flex`}>
                            <div className={s.star}></div>
                            <div className={s.star}></div>
                            <div className={s.star}></div>
                            <div className={s.star}></div>
                            <div className={s.star}></div>
                          </div>
                          {!!t.to &&
                            <Link to={t.to} className="text-decoration-none">
                              <h4 className={`${s.testimonialAuthor}`}>{t.author}</h4>
                            </Link>
                          }
                          {!t.to &&
                            <h4 className={`${s.testimonialAuthor}`}>{t.author}</h4>
                          }
                          <span className={s.testimonialPosition}>
                            {t.position}
                          </span>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </Slider>
            </div>
          </section>
        </>
      )}
      {layout === 5 && (
        <>
          <section className={`${s.testimonials} ${s.v5}`}>
            <div className="container">
              <div className="row">
                <div className="col-12">
                  <h2 className={s.testimonialsTitle}>{title}</h2>
                </div>
              </div>

              <Slider {...testimonialsSettingsV3}>
                {items.map((t, i) => {
                  return (
                    <div key={`testimonial-${i}`}>
                      <div className={`row align-items-center`}>
                        <div className="col-lg-6 col-xl-6 mb-3 mb-lg-0 order-1 order-lg-2">
                          <div className={s.testimonialMedia}>{t.image}</div>
                        </div>
                        <div className={`col-lg-6 col-xl-6 order-2 order-lg-1`}>
                          <div className="mb-2 pb-1">
                            <StaticImage
                              placeholder="none"
                              loading="eager"
                              src="../../images/testimonials/quotes-v2.png"
                              alt="quotes"
                            />
                          </div>
                          <div className={s.testimonialsText}>{t.text}</div>
                          <div className={`${s.stars} d-flex`}>
                            <div className={s.star}></div>
                            <div className={s.star}></div>
                            <div className={s.star}></div>
                            <div className={s.star}></div>
                            <div className={s.star}></div>
                          </div>
                          {!!t.to &&
                            <Link to={t.to} className="text-decoration-none">
                              <h4 className={`${s.testimonialAuthor}`}>{t.author}</h4>
                            </Link>
                          }
                          {!t.to &&
                            <h4 className={`${s.testimonialAuthor}`}>{t.author}</h4>
                          }
                          <span className={s.testimonialPosition}>
                            {t.position}
                          </span>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </Slider>
            </div>
          </section>
        </>
      )}
    </>
  );
};

export default Testimonials;
